






















import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
@Component({ name: 'DepartmentIndex' })
export default class DepartmentIndex extends VueBase {
  get getRouter() {
    const nowRoute = this.$store.getters.routers[0].children.filter(
      (item: any) => {
        return item.name == this.$route.matched[1].name
      }
    )
    return nowRoute[0].children
  }
}
